<template>
  <b-card>
    
    <header-table title="طلبات الأرشيف" @create="onClickedAdd" />

    
    <div class="border pt-1 px-1 rounded-lg shadow-sm mb-1">
      <b-row>
        <b-col cols="3">
          <form-input label="" v-model="filter" placeholder="  ابحث هنا عن اسم الأرشيف..." class="w-50" />
        </b-col>
        <b-col cols="1" ></b-col>
        <b-col cols="8">
          <div class="d-flex justify-content-end">
            <b-button
              v-for="(item, i) in filterDate"
              :key="i"
              size="md"
              variant="outline-success"
              pill
              class="mx-1"
              @click="
                utils.status = item.status;
                $refs.estatesTable.refreshTable();
              "
              :class="utils.status == item.status ? 'bg-light-success' : ''"
              >{{ item.label }}</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>

    <data-table ref="estatesTable" :filter="filter" :fields="fields"  :utils.sync="utils" ep="/archive-requests">
      <template #cell(actions)="row">
        <div>
          <b-button variant="flat-info" title="عرض المزيد من المعلومات" size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="" v-b-tooltip.hover.v-info.top @click="row.toggleDetails"
            v-b-toggle.collapse-1>
            المزيد
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
            <!-- ChevronUpIcon -->
          </b-button>
          <b-button v-can="{ slug: $route.meta.slug, action: 'O' }" v-if="utils.status=='N'" size="sm" variant="flat-danger" @click="confirm({ status: 'R', id: row.item.id }).then(() =>$refs.estatesTable.refreshTable())">
            <feather-icon icon="XCircleIcon" />
            <span class="align-middle">رفض</span>
          </b-button>
          <b-button v-can="{ slug: $route.meta.slug, action: 'O' }" v-if="utils.status=='N'" size="sm" variant="flat-success" @click="confirm({ status: 'A', id: row.item.id }).then(() =>$refs.estatesTable.refreshTable())">
            <feather-icon icon="CheckCircleIcon" />
            <span class="align-middle">قبول</span>
          </b-button>

          <b-dropdown v-if="utils.status=='N'" variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item v-can="{ slug: $route.meta.slug, action: 'U' }" @click="onUpdateClicked(row.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #row-details="row">
        <b-collapse id="collapse-1" class="mt-2">
          <b-row>
            <b-col cols="6">
              <div>
                <h6 class="text-primary">سبب الطلب</h6>
                <p>
                  {{ row.item.reason }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </template>
    </data-table>
    <form-modal ref="estateModalAdd" :loading="loading" :formSchema="estatesFormSchema" title="إضافة حجز"
      v-model="activModel" @confirm="onModalConfirmed">
    </form-modal>
  </b-card>
</template>
  
<script>
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  VBToggle,
  BCollapse
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import FormModal from "@/components/FormModal.vue";
import FormSelect from "@/components/form-select/index.vue";
import FormInput from "@/components/form-input/index.vue";
import { debounce } from "lodash";
import DataTable from "@/components/data-table/index";
import Ripple from "vue-ripple-directive";
import HeaderTable from "@/components/data-table/components/header-table.vue";

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BButton,
    FormModal,
    FormSelect,
    FormInput,
    DataTable,
    VBTooltip,
    VBToggle,
    BDropdown,
    BDropdownItem,
    BCollapse,
    HeaderTable
},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      activModel: false,
      filterDate: [
        { id: 1, label: "الطلبات الجديدة", status: "N" },
        { id: 1, label: "الطلبات المقبولة", status: "A" },
        { id: 1, label: "الطلبات المرفوضة", status: "R" },

      ],
      utils: {
        status: "N",
      },
      filter: "",
      procedure_id: "",
      fields: [
        { key: "archive.title", label: "الإرشيف" ,sortable:true },
        { key: "archive.date", label: "تاريخ الإرشيف" },
        { key: "archive.place", label: "مكان الإرشيف" },
        { key: "actions", label: "" },
      ],
      estatesFormSchema: {
        archive_id: {
          component: "FormSelect",
          key: "archive_id",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.title,
            id: "procedure_arcshev",
            label: "الأرشيف",
            ep: "select/archives",
            rules: "required",
          },
        },

        reason: {
          component: "FormInput",
          key: "reason",
          attrs: {
            label: "سبب الطلب",
            rules: "required",
          },
        },
        reason: {
          component: "FormInput",
          key: "reason",
          attrs: {
            label: "سبب الطلب",
            rules: "required",
          },
        },

        email: {
          component: "FormInput",
          key: "email",
          attrs: {
            label: "البريد الإلكتروني",
            rules: "required|email",
          },
        },
      },
    };
  },
  methods: {
    ...mapActions("manageCenter/requests/archives", ["confirm", "createRequestsArchives", "updateRequestsArchives", "deleteRequestsArchives"]),
    onModalConfirmed(form) {
      if (form.id) {
        this.updateRequestsArchives({ id: form.id, data: form }).then(() => {
          this.activModel = false;
          this.$refs.estatesTable.refreshTable();
        });
      } else {
        this.createRequestsArchives({ data: form }).then(() => {
          this.activModel = false;
          this.$refs.estatesTable.refreshTable();
        });
      }
    },

    onClickedAdd() {
      this.$refs.estateModalAdd.init({});
      this.activModel = true;
    },
    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        reason: form.reason,
        archive_id: form.archive.id,
        email: form.email,
        status: null
      };
      this.$refs.estateModalAdd.init(toUpdate);
      this.activModel = true;
    },

    onDelete(item) {
      // console.log(id)
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف الحجز", {
          title: "تأكيد حذف الحجز",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteLecturers({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },
  },
  watch: {
    procedure_id: debounce(function (value) {
      this.$refs.refprocedure.refreshOptions({
        procedure_id: this.procedure_id,
      });
    }, 700),
  },
  computed: {
    ...mapGetters("manageCenter/requests/archives", ["loading"]),
  },
};
</script>